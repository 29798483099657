import React, { useEffect, useState } from "react";

import { Button, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useNavigate } from "react-router-dom";
// import Swiper core and required modules
import SwiperCore, { Pagination, Scrollbar } from "swiper";
// modules styles
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
// swiper bundle styles
import "swiper/swiper-bundle.min.css";
// swiper core styles
import "swiper/swiper.min.css";

import "../assets/css/style2.css";

import DashSideNav from "../components/DashSideNav";
import { PATIENTVISIT_CREATE_RESET } from "../constants/patientConstants";

import Navbar from "../components/Navbar";
import Loader from "../components/Loader";
import Message from "../components/Message";

import { getDoctorDetails } from "../actions/doctorActions";
import { listDoctor } from "../actions/doctorActions";
import {
  getPatientDetails,
  createPatientVisit,
  getPatientdetailDetails
} from "../actions/patientActions";
// install Swiper modules
SwiperCore.use([Pagination, Scrollbar]);

export default function FileUpload() {

  const {id} = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();

  const patientDetails = useSelector((state) => state.patientDetails);
  const { error: error2, loading: loading2, patDeats } = patientDetails;


  const patientdetailDetails = useSelector((state) => state.patientdetailDetails);
  const { patDeats: patDeats2 } = patientdetailDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const patientvisitCreate = useSelector((state) => state.patientvisitCreate);
  const { success } = patientvisitCreate;

  const [patient, setPatient] = useState("");
  const [timestamp, setTimestamp] = useState(new Date());
  const [file, setFile] = useState("");
  console.log("🚀 ~ file: FileUpload.jsx ~ line 42 ~ FileUpload ~ file", file);
  const [location, setLocation] = useState("");
  const [clinical_condition, setCondition] = useState("");

  useEffect(() => {
    // dispatch(getPatientDetails());

    dispatch(getPatientdetailDetails(id));
    

    if (success) {
        
        history(`/pvd/${id}`);
        dispatch({ type: PATIENTVISIT_CREATE_RESET });
        
        
    }
  }, [dispatch, success]);

  const handleFileSelect = (event) => {
    setFile(event.target.files[0]);
  };

  const submitHandler = (e) => {
    console.log(e);

    e.preventDefault();
    dispatch(
      createPatientVisit({
        patient,
        timestamp,
        location,
        clinical_condition,
        file,
      })
    );
  };

  return (
    <>
      <Navbar />
      <section className="dashboard-sections">
        <div className="container-fluid">
          <div className="dashboard-inner row">
            <div className="left-sidebar col-lg-6">
              <nav className="navbar navbar-expand-lg">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <DashSideNav />
                </div>
              </nav>
            </div>
            <div className="patient-overview col-lg-9 ">
              <div>
                {/* <!-- Account details card--> */}
                <div className="card mb-4">
                  <div className="card-header text-cyan">
                    File Upload Patient
                  </div>

                  <div className="card-body">
                    <Form onSubmit={submitHandler}>
                      {/* <!-- Form Group (username)--> */}
                      <div className="row">
                        <div className="row mb-3">
                          {/* <!-- Form Group (organization name)--> */}

                          {/* <!-- Form Group (location)--> */}
                          <div className="col-md-6">
                            <Form.Label className="small mb-1">
                              Patient:
                            </Form.Label>
                            <span
                              style={{ color: "red", marginLeft: "0.1rem" }}
                            >
                              *
                            </span> 

                             <Form.Select
                              aria-label="Default select example"
                              defaultValue={patient}
                              onChange={(e) => {
                                setPatient(e.target.value);
                              }}
                              required
                            > 
                             <option value={localStorage.userId}>
                                Select a patient
                              </option> 
                             {
                                patDeats2?.map((doctor, index) => {
                                  return (
                                    <option
                                      key={doctor._id}
                                      value={doctor.name}
                                       
                                    >
                                      {doctor.name}
                                    </option>
                                  );
                                })}
                            </Form.Select>

                    
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <Form.Group controlId="location">
                                <Form.Label className="small mb-1">
                                  Location{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  row="5"
                                  value={location}
                                  onChange={(e) => setLocation(e.target.value)}
                                  placeholder="e.g C3, F7"
                                  required
                                ></Form.Control>
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <!-- Form Row--> */}

                      <div className="row mb-3">
                        {/* <!-- Form Group (organization name)--> */}

                        <div className="col-md-6">
                          <Form.Group
                            as={Col}
                            md="12"
                            xs="12"
                            controlId="validationCustom03"
                          >
                            <Form.Label className="small mb-1">
                              First Visit Date{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              onChange={(e) => setTimestamp(e.target.value)}
                              type="datetime-local"
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Enter a valid birth date.
                            </Form.Control.Feedback>
                            <Form.Control.Feedback>
                              Looks good!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          
                          <div className="mb-3">
                            <Form.Label className="small mb-1">
                              Clinical Condition:{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>

                            <Form.Select
                              aria-label="Default select example"
                              onChange={(e) => {
                                setCondition(e.target.value);
                              }}
                              defaultValue={clinical_condition}
                              required
                            >
                              <option value="">Select Patient condition</option>
                              <option value="2">Concussions</option>
                              <option value="1">Seizure</option>
                              <option value="3">Stroke</option>
                            </Form.Select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row gx-3 mb-3">
                            {/* <!-- Form Group (organization name)--> */}

                            {/* <!-- Form Group (location)--> */}

                            <div className="mb-3">
                              <Form.Group
                                controlId="formFileLg"
                                className="mb-3"
                              >
                                <Form.Label>
                                  EEG File{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="file"
                                  accept=".xlsx, .xls, .csv"
                                  onChange={handleFileSelect}
                                  required
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card-body p-3">
                        <Form.Group controlId="button">
                          <Button type="submit" variant="cyan">
                            Submit{" "}
                            {loading2 && (
                              <span className="spinner-border text-white spinner-border-sm ms-1"></span>
                            )}
                          </Button>
                        </Form.Group>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Swiper JS */}
    </>
  );
}
