import React from "react";
import { Link } from "react-router-dom";
import eeg_pen_image from "../assets/img/platform1.png";
import Navbar from "../components/Navbar";

import Footer from "../components/Footer";

function PenDetail() {
  return (
    <div className="bg-info.bg-gradient" id="layoutDefault_content">
      <div>
        <div id="layoutDefault">
          <div id="layoutDefault_content">
            <main>
              {/* <!-- Navbar--> */}

              <header>
                <div className="container navbar-light">
                  <Navbar />
                </div>
              </header>

              {/* <!-- Page Header--> */}

              <section
                className="bg-white py-15"
                style={{ fontFamily: "Lato" }}
              >
                <div className="container px-20" id="technology">
                  <div className="row gx-5 align-items-center justify-content-center">
                    <div className="col-lg-6">
                      <div className="badge bg-primary-soft text-primary badge-marketing rounded-pill mb-3">
                        CenSyn Platform
                      </div>
                      <div className="mb-5">
                        <h2>PenEEG™ and AI Platform</h2>
                        <p className="lead">
                          CenSyn's PenEEG™ and proprietary cloud software work
                          together to enable 30-second EEG recordings. The
                          hardware contain sensors to pick up electrical
                          activity in the brain, veiwed in the CenSyn scope iOS
                          app. Artificial intelligence (A.I) algorithms allow
                          for field diagnosis and long-term time based brain
                          health tracking and management.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-9 col-lg-6 " data-aos="slide-left">
                      <div className="mb-4">
                        <div className="content-skewed content-skewed-left">
                          <img
                            className="img-fluid rounded-3"
                            src={eeg_pen_image}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="bg-white py-5">
                <div className="container px-5">
                  <div className="row gx-5 mb-5">
                    <div className="col-lg-4 mb-5">
                      <a
                        className="card card-link border-bottom-0 border-start-0 border-end-0 border-top-lg border-primary lift text-center o-visible h-100"
                        href="#!"
                      >
                        <div className="card-body">
                          <div className="icon-stack icon-stack-xl bg-primary-soft text-primary mb-4 mt-n3 z-1 shadow">
                            <i data-feather="user"></i>
                          </div>
                          <h5>PenEEG™</h5>
                          <p className="card-text">
                            Handheld EEG device enabling brain data collection
                            in 30 seconds
                          </p>
                        </div>
                        <div className="card-footer">
                          <div className="text-primary fw-bold d-inline-flex align-items-center"></div>
                        </div>
                      </a>
                    </div>
                    <div className="col-lg-4 mb-5">
                      <a
                        className="card card-link border-bottom-0 border-start-0 border-end-0 border-top-lg border-secondary lift text-center o-visible h-100"
                        href="#!"
                      >
                        <div className="card-body">
                          <div className="icon-stack icon-stack-xl bg-secondary-soft text-secondary mb-4 mt-n3 z-1 shadow">
                            <i data-feather="life-buoy"></i>
                          </div>
                          <h5>CenSyn Scope</h5>
                          <p className="card-text">
                            Wirelessly view EEG data anytime, anywhere on phone
                            or tablet
                          </p>
                        </div>
                        <div className="card-footer">
                          <div className="text-secondary fw-bold d-inline-flex align-items-center"></div>
                        </div>
                      </a>
                    </div>
                    <div className="col-lg-4 mb-5">
                      <a
                        className="card card-link border-bottom-0 border-start-0 border-end-0 border-top-lg border-teal lift text-center o-visible h-100"
                        href="#!"
                      >
                        <div className="card-body">
                          <div className="icon-stack icon-stack-xl bg-teal-soft text-teal mb-4 mt-n3 z-1 shadow">
                            <i data-feather="tv"></i>
                          </div>
                          <h5>AI</h5>
                          <p className="card-text">
                            Get insights on potential diagnosis of brain health
                            & conditions
                          </p>
                        </div>
                        <div className="card-footer">
                          <div className="text-teal fw-bold d-inline-flex align-items-center"></div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </section>

              <section className="bg-white py-4">
                <div className="container px-5">
                  <div className="row gx-5 justify-content-center">
                    <div className="col-lg-10">
                      <div className="d-flex align-items-center justify-content-between">
                        <h2 className="mb-0">Hardware</h2>
                        <div className="badge bg-primary-soft text-primary badge-marketing">
                          PenEEG™
                        </div>
                      </div>
                      <hr className="mb-0" />
                      <ul className="list-group list-group-flush list-group-careers">
                        <li className="list-group-item">
                          <a href="#!">Reduced Montage</a>
                          <div className="small">2-channel EEG</div>
                        </li>
                        <li className="list-group-item">
                          <a href="#!">High Portability</a>
                          <div className="small">Pocket-size device</div>
                        </li>
                        <li className="list-group-item">
                          <a href="#!">Clinical grade electrodes</a>
                          <div className="small">Grass Ag/AgCl</div>
                        </li>
                        <li className="list-group-item">
                          <a href="#!">Wireless</a>
                          <div className="small">BLE5 Compatible</div>
                        </li>
                        <li className="list-group-item">
                          <a href="#!">Long-battery use</a>
                          <div className="small">LiPo battery</div>
                        </li>
                      </ul>
                      <div className="d-flex align-items-center justify-content-between">
                        <h2 className="mb-0">Software</h2>
                        <div className="badge bg-primary-soft text-primary badge-marketing">
                          Scope iOS app
                        </div>
                      </div>
                      <hr className="mb-0" />
                      <ul className="list-group list-group-flush list-group-careers">
                        <li className="list-group-item">
                          <a href="#!">Compatibility</a>
                          <div className="small">
                            Real-time smartphone & tablets
                          </div>
                        </li>
                        <li className="list-group-item">
                          <a href="#!">Digital Controls</a>
                          <div className="small">Cloud signal processing</div>
                        </li>
                        <li className="list-group-item">
                          <a href="#!">Smart Storage</a>
                          <div className="small">
                            Cloud storage & accessibility
                          </div>
                        </li>

                        <li className="list-group-item">
                          <a href="#!">User-friendly</a>
                          <div className="small">
                            Clinician inspired interface
                          </div>
                        </li>
                      </ul>
                      <div className="d-flex align-items-center justify-content-between">
                        <h2 className="mb-0">AI</h2>
                        <div className="badge bg-primary-soft text-primary badge-marketing">
                          Detection Algorithms
                        </div>
                      </div>
                      <hr className="mb-0" />
                      <ul className="list-group list-group-flush list-group-careers">
                        <li className="list-group-item">
                          <a href="#!">Accelerated diagnosis</a>
                          <div className="small">
                            Auto signal detection for review
                          </div>
                        </li>
                        <li className="list-group-item">
                          <a href="#!">Field diagnosis</a>
                          <div className="small">
                            Out-of-hospital EEG review
                          </div>
                        </li>
                        <li className="list-group-item">
                          <a href="#!">Longitudinal tracking</a>
                          <div className="small">Time based tracking</div>
                        </li>
                      </ul>

                      <hr className="my-5" />
                      {/* <div className="card bg-light shadow-none">
                        <div className="card-body text-center p-5">
                          <h2>Internships</h2>
                          <p className="lead mb-4">
                            Are you currently enrolled at a college or
                            university and looking for hands-on experience while
                            earning class credit? Take a look at our internship
                            opportunities!
                          </p>
                          <a className="btn btn-primary fw-500" href="#!">
                            Explore Internships
                          </a>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default PenDetail;
