import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Form, Button, ListGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { logout } from "../actions/userActions";

function DashSideNav() {
  const dispatch = useDispatch();
  const history = useNavigate();

  const logoutHandler = () => {
    dispatch(logout());
    history("/");
  };

  return (
    <div>
      <div id="layoutSidenav_nav ">
        <nav className="sidenav shadow-right sidenav-light">
          <div className="sidenav-menu">
            <div className="nav accordion" id="accordionSidenav">
              <div className="sidenav-menu-heading d-sm-none">Account</div>

              <div className="sidenav-menu-heading">Core</div>

              <Link to="/dash">
                <a
                  className="nav-link collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseDashboards"
                  aria-expanded="false"
                  aria-controls="collapseDashboards"
                >
                  <div className="nav-link-icon">
                    <i data-feather="activity"></i>
                  </div>
                  Portal
                  {/* <div className="sidenav-collapse-arrow">
                  <i className="fas fa-angle-down"></i>
                </div> */}
                </a>
              </Link>

              <div className="sidenav-menu-heading">Patients</div>

              <Link to="/dash">
                <a
                  className="nav-link collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsePages"
                  aria-expanded="false"
                  aria-controls="collapsePages"
                >
                  <div className="nav-link-icon">
                    <i data-feather="grid"></i>
                  </div>
                  New Patient
                  {/* <div className="sidenav-collapse-arrow">
                  <i className="fas fa-angle-down"></i>
                </div> */}
                </a>
              </Link>

              <Link to="/dash">
                <a
                  className="nav-link collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseApps"
                  aria-expanded="false"
                  aria-controls="collapseApps"
                >
                  <div className="nav-link-icon">
                    <i data-feather="globe"></i>
                  </div>
                  Manage
                  {/* <div className="sidenav-collapse-arrow">
                  <i className="fas fa-angle-down"></i>
                </div> */}
                </a>
              </Link>

              <Link to="/dash">
                <a
                  className="nav-link collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFlows"
                  aria-expanded="false"
                  aria-controls="collapseFlows"
                >
                  <div className="nav-link-icon">
                    <i data-feather="repeat"></i>
                  </div>
                  Recordings
                  {/* <div className="sidenav-collapse-arrow">
                    <i className="fas fa-angle-down"></i>
                  </div> */}
                </a>
              </Link>

              <div className="sidenav-menu-heading">AI</div>

              <Link to="/dash">
                <a
                  className="nav-link collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseLayouts"
                  aria-expanded="false"
                  aria-controls="collapseLayouts"
                >
                  <div className="nav-link-icon">
                    <i data-feather="layout"></i>
                  </div>
                  Epochs
                  {/* <div className="sidenav-collapse-arrow">
                    <i className="fas fa-angle-down"></i>
                  </div> */}
                </a>
              </Link>

              <Link to="/dash">
                <a
                  className="nav-link collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseComponents"
                  aria-expanded="false"
                  aria-controls="collapseComponents"
                >
                  <div className="nav-link-icon">
                    <i data-feather="package"></i>
                  </div>
                  Similarity
                  {/* <div className="sidenav-collapse-arrow">
                  <i className="fas fa-angle-down"></i>
                </div> */}
                </a>
              </Link>

              {/* <a className="nav-link collapsed" href="/#" data-bs-toggle="collapse" data-bs-target="#collapseUtilities" aria-expanded="false" aria-controls="collapseUtilities">
                                    <div className="nav-link-icon"><i data-feather="tool"></i></div>
                                    Utilities
                                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                </a> */}

              <div className="sidenav-menu-heading">Settings</div>

              <Link to="/dash">
                <a className="nav-link">
                  <div className="nav-link-icon">
                    <i data-feather="bar-chart"></i>
                  </div>
                  Profile
                </a>
              </Link>

              <Link to="/dash">
                <a className="nav-link">
                  <div className="nav-link-icon">
                    <i data-feather="filter"></i>
                  </div>
                  Payment
                </a>
              </Link>

            </div>
          </div>

          <div className="sidenav-footer">
            <div className="sidenav-footer-content">
              <div className="sidenav-footer-subtitle">
                <button
                  className="logout btn btn-lg btn-teal"
                  onClick={logoutHandler}
                >
                  Sign Out
                </button>
              </div>
              <div className="sidenav-footer-title"></div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default DashSideNav;
