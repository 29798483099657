import axios from "axios";
import { toast } from "react-toastify";

import {
  PATIENT_DETAILS_REQUEST,
  PATIENT_DETAILS_SUCCESS,
  PATIENT_DETAILS_FAIL,
  PATIENTVISIT_CREATE_REQUEST,
  PATIENTVISIT_CREATE_SUCCESS,
  PATIENTVISIT_CREATE_FAIL,
  PATIENTVISIT_CREATE_RESET,
  PATIENTVISIT_LIST_FAIL,
  PATIENTVISIT_LIST_REQUEST,
  PATIENTVISIT_LIST_SUCCESS,
  PATIENTDETAIL_DETAILS_REQUEST,
  PATIENTDETAIL_DETAILS_SUCCESS,
  PATIENTDETAIL_DETAILS_FAIL,
} from "../constants/patientConstants";

export const getPatientDetails = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PATIENT_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/patient/`,
      config
    );

    dispatch({
      type: PATIENT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PATIENT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createPatientVisit = (patient) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PATIENTVISIT_CREATE_REQUEST,
    });

    const {} = getState();
    // get all the keys of patient and append them in form data
    const formData = new FormData();
    Object.keys(patient).forEach((key) => {
      formData.append(key, patient[key]);
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/upload/`,
      formData,
      config
    );

    if (data.code === 200) {
      toast.success("Patient Visit Created Successfully", {
        position: "top-right",
      });
    } else {
      toast.error("Something bad happened", {
        position: "top-right",
      });
    }

    dispatch({
      type: PATIENTVISIT_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.error("Patient Visit Creation Failed", {
      position: "top-right",
    });
    dispatch({
      type: PATIENTVISIT_CREATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listPatientVisit = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PATIENTVISIT_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/pvd/${id}`,
      config
    );
    dispatch({
      type: PATIENTVISIT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PATIENTVISIT_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

export const getPatientdetailDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PATIENTDETAIL_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/detail/${id}`,
      config
    );
    dispatch({
      type: PATIENTDETAIL_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PATIENTDETAIL_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};
