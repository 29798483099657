import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/Message";

import { toast, ToastContainer } from "react-toastify";
import { Chart } from "react-chartjs-2";
import ClipLoader from "react-spinners/ClipLoader";

import Navbar from "../components/Navbar";
import DashSideNav from "../components/DashSideNav";
import Footer from "../components/Footer";

function Recordings() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();

  const [startRange, setStartRange] = useState(0);
  const [endRange, setEndRange] = useState(0);

  const [value, setValue] = React.useState([0, 1000]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setStartRange(newValue[0]);
    setEndRange(newValue[1]);
    // console.log(newValue);
  };

  const [dataCount, setDataCount] = useState(0);
  const [myChart, setMyChart] = useState(null);
  const [dataLength, setDataLength] = useState(null);
  const [eegData, setEegData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState();
  const isMounted = useRef(false);
  const myRef = useRef();
  const [isFilter, setIsFilter] = useState(false);
  const [filteredEegData, setFilteredEegData] = useState([]);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (isMounted.current) {
      if (eegData.length === 0) {
        return;
      }
      if (myChart != null) {
        myChart.destroy();
      }
      setMyChart(
        new Chart(myRef.current, {
          type: "line",
          data: {
            labels: eegData.length === 0 ? [] : eegData.length[dataCount],
            datasets: [
              {
                label: "CH1 Voltage",
                data:
                  eegData.length === 0
                    ? []
                    : isFilter
                    ? filteredEegData.CH1_Voltage[dataCount]
                    : eegData.CH1_Voltage[dataCount],
                backgroundColor: ["rgba(55, 212, 123,0.6)"],
                pointRadius: 0,
                borderWidth: 1.0,
                borderColor: ["rgba(55, 212, 123,0.8)"],
              },
              // {
              //   label: "CH2 Voltage",
              //   data:
              //     eegData.length === 0
              //       ? []
              //       : isFilter
              //       ? filteredEegData.CH2_Voltage[dataCount]
              //       : eegData.CH2_Voltage[dataCount],
              //   backgroundColor: ["rgba(64, 163, 230,0.6)"],
              //   pointRadius: 0,
              //   borderWidth: 1.3,
              //   borderColor: ["rgba(64, 163, 230,0.8)"],
              // },
            ],
          },
          width: 400,
          height: 1000,
          options: {
            scales: {
              x: {
                title: {
                  display: true,
                  text: "Time (seconds)",
                },
              },
              y: {
                title: {
                  display: true,
                  text: "Voltages",
                },
              },
            },
            normalized: true,
            maintainAspectRatio: false,
            animation: false,
            spanGaps: true,
            datasets: {
              line: {
                pointRadius: 0, // disable for all `'line'` datasets
              },
            },
            elements: {
              point: {
                radius: 0, // default to disabled in all datasets
              },
            },
            plugins: {
              decimation: {
                enabled: true,
              },
              zoom: {
                pan: {
                  enabled: true,
                  mode: "x",
                  rangeMin: { x: 0 },
                  rangeMax: { x: 100 },
                },
                zoom: {
                  wheel: {
                    enabled: true,
                    mode: "xy",
                    rangeMin: { x: 0 },
                    rangeMax: { x: 100 },
                  },
                  pinch: {
                    enabled: true,
                  },
                  mode: "x",
                },
              },
            },
          },
        })
      );
    }
  }, [eegData, dataCount]);

  useEffect(() => {
    try {
    } catch (error) {
      console.log(error);
    }
    const getData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/recordings/eeg_file/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userInfo.token}`,
            },
          }
        );
        if (response.data.code === 401) {
          localStorage.clear();
          window.location.reload();
        } else {
          isMounted.current = true;
          if (response.data.message !== "error") {
            setEegData(response.data.data);
            setDataLength(response.data.data.length.length);
            setLoading(false);
          } else {
            setAlert(response.data.message);
          }
        }
      } catch (error) {
        toast.error("No relevant file EEG file found for the given patient", {
          autoClose: 3000,
          position: "top-right",
        });
        setLoading(false);
        // setAlert("No relevant file EEG file found for the given patient");
      }
    };

    getData();
  }, []);

  return (
    <section className="dashbpard-sections">
      <div className="container-fluid">
        <Navbar />
        <div className="dashboard-inner row">
          <div className="left-sidebar col-lg-2">
            <nav className="navbar navbar-expand-lg">
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <DashSideNav />
              </div>
            </nav>
          </div>
          <div className="patient-overview col-lg-10">
            <div className="card mb-4">
              <div className="card-header text-black">Patients</div>
              <div className="row">
                <div className="graph-box col-md-11 mx-auto ">
                  <canvas id="chart" ref={myRef} height="400" />
                </div>

                {loading ? (
                  <div
                    style={{
                      textAlign: "center",
                      paddingTop: "2%",
                    }}
                  >
                    {" "}
                    <ClipLoader />{" "}
                    <h3 style={{ fontFamily: "lato" }}>Graph Loading...</h3>
                  </div>
                ) : (
                  <div className="empty-box ">
                    <div>
                      <button
                        disabled={dataCount === 0 ? true : false}
                        className={
                          dataCount === 0 ? "btn grey-btn" : "btn green-btn"
                        }
                        onClick={() => {
                          setDataCount(dataCount - 1);
                          setValue([0, 1000]);
                        }}
                      >
                        Previous Window
                      </button>
                    </div>

                    <div>
                      <button
                      
                        disabled={dataCount === dataLength - 1 ? true : false}
                        className={
                          dataCount === dataLength - 1
                            ? "btn grey-btn"
                            : "btn green-btn"
                        }
                        onClick={() => {
                          setDataCount(dataCount + 1);
                          setValue([0, 1000]);
                        }}
                        
                      >
                        Next Window
                      </button>
                    </div>
                  </div>
                )}
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Recordings;
