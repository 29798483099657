import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/Message";
import FormContainer from "../components/FormContainer";
import { register } from "../actions/userActions";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function Register() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();

  const history = useNavigate();

  const userRegister = useSelector((state) => state.userRegister);
  const { error, loading, userInfo } = userRegister;

  useEffect(() => {
    if (userInfo) {
      history("/dash");
    }
  }, [history, userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();

    if (password != confirmPassword) {
      setMessage("Passwords do not match");
    } else {
      dispatch(register(name, email, password));
    }
  };

  return (
    <div>
      <div id="layoutAuthentication_content">
        <main>
          <div className="container-xl px-4">
            <div className="row justify-content-center">
              <header>
                <div className="container ">
                  <Navbar />
                </div>
              </header>
              <div className="col-lg-5">
                <div className="card shadow-lg border-0 rounded-lg mt-5">
                  <div className="card-header justify-content-center">
                    <h3 className="fw-light my-4">Sign Up</h3>
                  </div>
                  <div className="card-body">
                    {message && <Message variant="danger">{message}</Message>}
                    {error && <Message variant="danger">{error}</Message>}
                    {loading && <Loader />}
                    <Form onSubmit={submitHandler}>
                      <Form.Group controlId="name">
                        <Form.Label>
                          Name
                          <span style={{ color: "red", marginLeft: "0.1rem" }}>
                            *
                          </span>
                        </Form.Label>
                        <Form.Control
                          required
                          type="name"
                          placeholder="Enter name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group controlId="email">
                        <Form.Label>
                          Email Address{" "}
                          <span style={{ color: "red", marginLeft: "0.1rem" }}>
                            *
                          </span>
                        </Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="Enter Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group controlId="password">
                        <Form.Label>
                          Password
                          <span style={{ color: "red", marginLeft: "0.1rem" }}>
                            *
                          </span>
                        </Form.Label>
                        <Form.Control
                          required
                          type="password"
                          placeholder="Enter Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group controlId="passwordConfirm">
                        <Form.Label>
                          Confirm Password{" "}
                          <span style={{ color: "red", marginLeft: "0.1rem" }}>
                            *
                          </span>
                        </Form.Label>
                        <Form.Control
                          required
                          type="password"
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group>
                        <Row className="py-3">
                          <Col>
                            <Button type="submit" variant="teal">
                              Register
                            </Button>
                          </Col>
                        </Row>
                      </Form.Group>
                    </Form>
                  </div>
                  <div className="card-footer text-center">
                    <div className="small">
                      <a href="/login">Have an account? Sign in!</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <div id="layoutDefault_footer">
        <Footer />
      </div>
    </div>
  );
}

export default Register;
