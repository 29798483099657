import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
// import Swiper core and required modules
import SwiperCore, { Pagination, Scrollbar } from "swiper";
// modules styles
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
// swiper bundle styles
import "swiper/swiper-bundle.min.css";
// swiper core styles
import "swiper/swiper.min.css";
import "../assets/css/style2.css";
import patient_image2 from "../assets/img/default.jpg";
import DashSideNav from "../components/DashSideNav";
import Navbar from "../components/Navbar";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { createDoctor } from "../actions/doctorActions";
import { DOCTOR_CREATE_RESET } from "../constants/doctorConstants";
import { getDoctorDetails } from "../actions/doctorActions";

// install Swiper modules

export default function Deats() {
  const doctorCreate = useSelector((state) => state.doctorCreate);
  const { success } = doctorCreate;
  const doctorDetails = useSelector((state) => state.doctorDetails);
  const { error, loading, doctor } = doctorDetails;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [hospital, setHospital] = useState("");

  const dispatch = useDispatch();
  const history = useNavigate();

  useEffect(() => {
    if (success) {
      dispatch({ type: DOCTOR_CREATE_RESET });
      history("/dash");
      window.location.reload()
    } 
  }, [dispatch, success]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createDoctor({
        firstName,
        lastName,
        email,
        phoneNo,
        hospital,
      })
    );
  };

  return (
    <>
      <section className="dashbpard-sections">
        <div className="container-fluid">
          <Navbar />
          <div className="dashboard-inner row">
            <div className="left-sidebar col-lg-2">
              <nav className="navbar navbar-expand-lg">
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <DashSideNav />
                </div>
              </nav>
            </div>
            <div className="patient-overview col-lg-10">
              <div className="card mb-4">
                <div className="card-header text-black">
                  Add your Details
                </div>
                <div className="slide-boxes">
                  <div className="swiper mySwiper">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <div className="row">
                          <Form onSubmit={submitHandler}>
                            {/* <!-- Form Group (username)--> */}
                            <div className="mb-3">
                              <Form.Group controlId="name">
                                <Form.Label className="small mb-1">
                                  First Name
                                </Form.Label>
                                <Form.Control
                                  row="5"
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                ></Form.Control>
                              </Form.Group>
                            </div>
                            {/* <!-- Form Row--> */}

                            <div className="row gx-3 mb-3">
                              {/* <!-- Form Group (organization name)--> */}
                              <div className="col-md-6">
                                <Row>
                                  <Form.Group
                                    as={Col}
                                    md="12"
                                    xs="12"
                                    controlId="validationCustom03"
                                  >
                                    <Form.Label className="small mb-1">
                                      Last Name
                                    </Form.Label>
                                    <Form.Control
                                      row="5"
                                      value={lastName}
                                      onChange={(e) =>
                                        setLastName(e.target.value)
                                      }
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                      Enter a valid Last Name.
                                    </Form.Control.Feedback>
                                    <Form.Control.Feedback>
                                      Looks good!
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Row>
                              </div>
                              {/* <!-- Form Group (location)--> */}
                              <div className="col-md-6">
                                <Form.Group controlId="gender">
                                <Form.Label className="small mb-1">
                                      Email
                                    </Form.Label>
                                    <Form.Control
                                      row="5"
                                      value={email}
                                      onChange={(e) =>
                                        setEmail(e.target.value)
                                      }
                                    ></Form.Control>
                                </Form.Group>
                              </div>
                            </div>
                            {/* <!-- Form Group (email address)--> */}
                            <div className="mb-3">
                              <Row>
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  xs="12"
                                  controlId="validationCustom03"
                                >
                                  <Form.Label className="small mb-1">
                                      Phone Number
                                    </Form.Label>
                                    <Form.Control
                                      row="5"
                                      value={phoneNo}
                                      onChange={(e) =>
                                        setPhoneNo(e.target.value)
                                      }
                                    ></Form.Control>
                                  <Form.Control.Feedback type="invalid">
                                    Enter a valid Phone Number.
                                  </Form.Control.Feedback>
                                  <Form.Control.Feedback>
                                    Looks good!
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>
                            </div>
                            {/* <!-- Form Row--> */}
                            <div className="row gx-3 mb-3">
                              {/* <!-- Form Group (phone number)--> */}
                              <div className="col-md-6">
                                <Form.Group controlId="clinical_condition">
                                <Form.Label className="small mb-1">
                                      Hospital
                                    </Form.Label>
                                    <Form.Control
                                      row="5"
                                      value={hospital}
                                      onChange={(e) =>
                                        setHospital(e.target.value)
                                      }
                                    ></Form.Control>
                                </Form.Group>
                              </div>
                              {/* <!-- Form Group (birthday)--> */}
                              {/* <div className="col-md-6">
                                <Form.Group controlId="blood_group">
                                  <Form.Label className="small mb-1">
                                    Blood Group
                                  </Form.Label>
                                  <Form.Control
                                    row="5"
                                    value={blood_group}
                                    onChange={(e) => setBG(e.target.value)}
                                  ></Form.Control>
                                </Form.Group>
                              </div> */}
                            </div>

                            <div className="card-body p-3">
                              <Form.Group controlId="button">
                                <Button type="submit" variant="cyan">
                                  Submit
                                </Button>
                              </Form.Group>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-pagination" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
