export const DOCTOR_LIST_REQUEST = 'DOCTOR_LIST_REQUEST'
export const DOCTOR_LIST_SUCCESS = 'DOCTOR_LIST_SUCCESS'
export const DOCTOR_LIST_FAIL = 'DOCTOR_LIST_FAIL'

export const DOCTOR_CREATE_REQUEST = 'DOCTOR_CREATE_REQUEST'
export const DOCTOR_CREATE_SUCCESS = 'DOCTOR_CREATE_SUCCESS'
export const DOCTOR_CREATE_FAIL = 'DOCTOR_CREATE_FAIL'
export const DOCTOR_CREATE_RESET = 'DOCTOR_CREATE_RESET'

export const DOCTOR_DETAILS_REQUEST = 'DOCTOR_DETAILS_REQUEST'
export const DOCTOR_DETAILS_SUCCESS = 'DOCTOR_DETAILS_SUCCESS'
export const DOCTOR_DETAILS_FAIL = 'DOCTOR_DETAILS_FAIL'

