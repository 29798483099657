import axios from "axios";
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
// import Swiper core and required modules
import SwiperCore, { Pagination, Scrollbar } from "swiper";
// modules styles
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
// swiper bundle styles
import "swiper/swiper-bundle.min.css";
// swiper core styles
import "swiper/swiper.min.css";
import "../assets/css/style2.css";
import { Form, Button, Row, Col, Table } from "react-bootstrap";

import DashSideNav from "../components/DashSideNav";
import Navbar from "../components/Navbar";
import Loader from "../components/Loader";
import Message from "../components/Message";

import { getDoctorDetails } from "../actions/doctorActions";

export default function Profile() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const doctorDetails = useSelector((state) => state.doctorDetails);
  const { error, loading, docDeats } = doctorDetails;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {

    if (userInfo) {
      dispatch(getDoctorDetails())
    }

   
}, [dispatch])

  return (
    <>
      <section className="dashbpard-sections">
        <div className="container-fluid">
          <Navbar />
          <div className="dashboard-inner row">
            <div className="left-sidebar col-lg-2">
              <nav className="navbar navbar-expand-lg">
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <DashSideNav />
                </div>
              </nav>
            </div>
            <div className="patient-overview col-lg-10">
              <div className="card mb-4">
                <div className="card-header text-black">
                  Doctor Details
                </div>
                <div className="slide-boxes">
                  <div className="swiper mySwiper">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <div className="row">
                          
                            
                            {loading ? (
                              <Loader variant="danger">Please add your details</Loader>
                            ) : error ? (
                              <Message variant="danger">{error}</Message>
                            ) : (
                              <Table striped responsive className="table-sm">
                                <thead>
                                  <tr>
                                    
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    <th>Hospital</th>
                                    <th></th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {docDeats.map((order) => (
                                    <tr key={order.id}>
                                      
                                      <td>
                                        {order.name}
                                      </td>
                                      <td>{order.email}</td>
                                      <td>
                                        {order.phone_no}
                                      </td>
                                      <td>
                                        {order.hospital}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            )}
                          
                        </div>
                      </div>
                    </div>
                    <div className="swiper-pagination" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
