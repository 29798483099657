import { Container } from 'react-bootstrap'

import {
  HashRouter as Router,
  Switch,
  Route,
  Routes,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";


import Test from './screens/Test';
import HomeScreen from './screens/HomeScreen';
import PenDetail from './screens/PenDetail';
import Dashboard from './screens/DashScreen';
import Login from './screens/LoginScreen';
import Register from './screens/SignUp';
import Deats from './screens/UserDetails.js';
import Profile from './screens/ProfileScreen';
import FileUpload from './screens/FileUpload';
import PatientVisits from './screens/PvdScreen';
import Recordings from './screens/RecordingScreen';

import './App.css';

function App() {
  return (

    <Router>
     
      <main className="bg-white py-5">
        <Container>
          <Routes>

          <Route path='/' element={<HomeScreen />} exact />
          <Route path='/technology' element={<PenDetail />} exact />
          <Route path='/login' element={<Login />} exact />
          <Route path='/dash' element={<Dashboard />} exact />
          <Route path='/details' element={<Deats />} exact />
          <Route path='/signup' element={<Register />} exact />
          <Route path='/profile' element={<Profile />} exact />
          <Route path='/fileupload/:id' element={<FileUpload />} exact />
          <Route path='/pvd/:id' element={<PatientVisits />} />
          <Route path='/recording/:id' element={<Recordings />} />
          <Route path='/test' element={<Test />} exact />

          
           
        
  
          </Routes>
        </Container>
      </main>
      
    </Router>
    
  );
}

export default App;
