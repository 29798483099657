export const PATIENT_DETAILS_REQUEST = 'PATIENT_DETAILS_REQUEST'
export const PATIENT_DETAILS_SUCCESS = 'PATIENT_DETAILS_SUCCESS'
export const PATIENT_DETAILS_FAIL = 'PATIENT_DETAILS_FAIL'

export const PATIENTVISIT_CREATE_REQUEST = 'PATIENTVISIT_CREATE_REQUEST'
export const PATIENTVISIT_CREATE_SUCCESS = 'PATIENTVISIT_CREATE_SUCCESS'
export const PATIENTVISIT_CREATE_FAIL = 'PATIENTVISIT_CREATE_FAIL'
export const PATIENTVISIT_CREATE_RESET = 'PATIENTVISIT_CREATE_RESET'

export const PATIENTVISIT_LIST_REQUEST = 'PATIENTVISIT_LIST_REQUEST'
export const PATIENTVISIT_LIST_SUCCESS = 'PATIENTVISIT_LIST_SUCCESS'
export const PATIENTVISIT_LIST_FAIL = 'PATIENTVIST_LIST_FAIL'

export const PATIENTDETAIL_DETAILS_REQUEST = 'PATIENTDETAIL_DETAILS_REQUEST'
export const PATIENTDETAIL_DETAILS_SUCCESS = 'PATIENTDETAIL_DETAILS_SUCCESS'
export const PATIENTDETAIL_DETAILS_FAIL = 'PATIENTDETAIL_DETAILS_FAIL'