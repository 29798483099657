import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Table,
  ListGroup,
  Image,
  Form,
  Button,
  Card,
} from "react-bootstrap";

import DashSideNav from "../components/DashSideNav";
import Navbar from "../components/Navbar";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { listPatientVisit } from "../actions/patientActions";

export default function PatientVisits() {
  const [patientVisits, setPatientVisits] = useState([]);
  const [patientName, setPatientName] = useState();

  const patientVisitList = useSelector((state) => state.patientVisitList);
  const { patients, loading, error } = patientVisitList;

  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();


  useEffect(() => {

    if (id) {
        dispatch(listPatientVisit(id));

    }
     
       
  }, [dispatch, id]);

  return (
    <div>
      <section className="dashbpard-sections">
        <div className="container-fluid">
          <Navbar />
          <div className="dashboard-inner row">
            <div className="left-sidebar col-lg-2">
              <nav className="navbar navbar-expand-lg">
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <DashSideNav />
                </div>
              </nav>
            </div>
            <div className="patient-overview col-lg-10">
              <div className="card mb-4">
                <div className="card-header text-black">Patient Visits</div>
                <div className="slide-boxes">
                  <div className="swiper mySwiper">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <div className="row">
                          {loading ? (
                            <Loader />
                          ) : error ? (
                            <Message variant="danger">{error}</Message>
                          ) : patients ? (
                            <table className="table">
                              <thead>
                                <tr
                                  style={{
                                    color: "#166e78",
                                    fontSize: "22px",
                                    textAlign: "center",
                                  }}
                                >
                                  {/* <th scope="col">Patient ID</th> */}
                                  <th scope={"col"}>Condition</th>
                                  <th scope="col">Location</th>
                                  <th scope="col">Timestamp</th>
                                  
                                  
                                  
                                  <th scope="col">Recordings</th>
                                </tr>
                              </thead>
                              <tbody>
                                 {patients?.data?.visits?.map((item) => (
                                  <tr
                                    style={{ textAlign: "center" }}
                                    key={item._id}
                                  >
                                    <td>
                                      {item.clinical_condition == "1"
                                        ? "Seizure"
                                        : item.clinical_condition == "2"
                                        ? "Concussion"
                                        : item.clinical_condition == "3"
                                        ? "Stroke"
                                        : ""}
                                    </td>
                                    <td>{item.location}</td>
                                    <td>{item.timestamp}</td>
                                    
                                    
                                    
                                    <td>
                                      {" "}
                                      <Link
                                        to={`/recording/${item._id}`}
                                        className="btn btn-info btn-block "
                                        onClick={() => {
                                          localStorage.setItem(
                                            "patientVisitId",
                                            item.id
                                          );
                                        }}
                                      >
                                        View
                                      </Link>
                                    </td>
                                  </tr>
                                ))} 
                              </tbody>
                            </table>
                          ): <div>test</div>}
                        </div>
                      </div>
                    </div>
                    <div className="swiper-pagination" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
