import axios from "axios";

import {
  DOCTOR_LIST_REQUEST,
  DOCTOR_LIST_SUCCESS,
  DOCTOR_LIST_FAIL,

  DOCTOR_CREATE_REQUEST,
  DOCTOR_CREATE_SUCCESS,
  DOCTOR_CREATE_FAIL,
  DOCTOR_CREATE_RESET,

  DOCTOR_DETAILS_REQUEST,
  DOCTOR_DETAILS_SUCCESS,
  DOCTOR_DETAILS_FAIL,

  
} from "../constants/doctorConstants";




export const listDoctor = () => async (dispatch) => {
    

    try{
        dispatch({type:DOCTOR_LIST_REQUEST })
        const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}/doctorlist/`)

        dispatch({
            type:DOCTOR_LIST_SUCCESS,
            payload: data 
        })

    }catch(error){
        dispatch({
            type:DOCTOR_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.detail, 
        })

    }
}

export const createDoctor = (doctor) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DOCTOR_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/details/`,
            doctor,
            config
        )

        dispatch({
            type: DOCTOR_CREATE_SUCCESS,
            payload: data
        })

        dispatch(getDoctorDetails())

        
    } catch (error) {
        dispatch({
            type: DOCTOR_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getDoctorDetails = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: DOCTOR_DETAILS_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/drdetails`,
            config
        )

        dispatch({
            type: DOCTOR_DETAILS_SUCCESS,
            payload: data
        })



    } catch (error) {
        dispatch({
            type: DOCTOR_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}