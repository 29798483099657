import axios from "axios";

import {
  DOCTOR_LIST_REQUEST,
  DOCTOR_LIST_SUCCESS,
  DOCTOR_LIST_FAIL,

  DOCTOR_CREATE_REQUEST,
  DOCTOR_CREATE_SUCCESS,
  DOCTOR_CREATE_FAIL,
  DOCTOR_CREATE_RESET,

  DOCTOR_DETAILS_REQUEST,
  DOCTOR_DETAILS_SUCCESS,
  DOCTOR_DETAILS_FAIL,

  
} from "../constants/doctorConstants";


export const doctorListReducer = (state = { doctors: [] }, action) => {
    switch (action.type) {
      case DOCTOR_LIST_REQUEST:
        return { loading: true, doctors: [] };
  
      case DOCTOR_LIST_SUCCESS:
        return {
          loading: false,
          doctors: action.payload,
        };
  
      case DOCTOR_LIST_FAIL:
        return { loading: false, error: action.payload };
  
      
      default:
        return state;
    }
  };

  export const doctorCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case DOCTOR_CREATE_REQUEST:
            return {
                loading: true
            }

        case DOCTOR_CREATE_SUCCESS:
            return {
                loading: false,
                success: true,
                order: action.payload
            }

        case DOCTOR_CREATE_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        
        case DOCTOR_CREATE_RESET:
            return {}



        default:
            return state
    }
}

export const doctorDetailsReducer = (state = { loading: true, docs: []}, action) => {
  switch (action.type) {
      case DOCTOR_DETAILS_REQUEST:
          return {
              ...state,
              loading: true
          }

      case DOCTOR_DETAILS_SUCCESS:
          return {
              loading: false,
              docDeats: action.payload
          }

      case DOCTOR_DETAILS_FAIL:
          return {
              loading: false,
              error: action.payload
          }


      default:
          return state
  }
}