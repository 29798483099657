import axios from "axios";
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
// import Swiper core and required modules
import SwiperCore, { Pagination, Scrollbar } from "swiper";
// modules styles
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
// swiper bundle styles
import "swiper/swiper-bundle.min.css";
// swiper core styles
import "swiper/swiper.min.css";
import "../assets/css/style2.css";
import { Form, Button, Row, Col, Table } from "react-bootstrap";
import patient_image2 from "../assets/img/default.jpg";

import DashSideNav from "../components/DashSideNav";
import Navbar from "../components/Navbar";
import Loader from "../components/Loader";
import Message from "../components/Message";

import { getDoctorDetails } from "../actions/doctorActions";
import { listDoctor } from "../actions/doctorActions";
import { getPatientDetails } from "../actions/patientActions";

export default function Test() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const doctorDetails = useSelector((state) => state.doctorDetails);
  const { error, loading, docDeats } = doctorDetails;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const patientDetails = useSelector((state) => state.patientDetails);
  const { error: error2, loading: loading2, patDeats } = patientDetails;

  useEffect(() => {
    if (!docDeats) {
      dispatch(getDoctorDetails());
    } else {
      if (docDeats[0]) {
        dispatch(getPatientDetails());
      } else {
        history("/details");
      }
    }
  }, [dispatch, docDeats, history]);

  return (
    <>
      <section className="dashbpard-sections">
        <div className="container-fluid">
          <Navbar />
          <div className="dashboard-inner row">
            <div className="left-sidebar col-lg-2">
              <nav className="navbar navbar-expand-lg">
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <DashSideNav />
                </div>
              </nav>
            </div>
            <div className="patient-overview col-lg-10">
              <div className="overview-mainbox">
                <div className="overview-title">
                  <h3>Patient Overview</h3>
                </div>
                <div className="slide-boxes">
                  <div className="swiper mySwiper">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <div className="row">
                          {/* {loading2 ? (
                              <Loader variant="danger">Please add your details</Loader>
                            ) : error2 ? (
                              <Message variant="danger">{error}</Message>
                            ) : (
                              <Table striped responsive className="table-sm">
                                <thead>
                                  <tr>
                                    
                                    <th>Name</th>
                                    <th>Age</th>
                                    <th>Gender</th>
                                    <th>Condition</th>
                                    <th></th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {patDeats.map((order) => (
                                    <tr key={order.id}>
                                      
                                      <td>
                                        {order.name}
                                      </td>
                                      <td>{order.age}</td>
                                      <td>
                                        {order.gender}
                                      </td>
                                      <td>
                                        {order.clinical_condition}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            )} */}

                          <Swiper
                            autoHeight="true"
                            slidesPerView={patDeats.length > 1 ? "2" : "1"}
                            slidesPerColumn="auto"
                            // slidesPerColumnFill="row"
                            spaceBetween={0}
                            breakpoints={{
                              575: {
                                slidesPerView: 1,
                              },
                              640: {
                                slidesPerView: 2,
                              },
                            }}
                            scrollbar={{
                              hide: true,
                            }}
                            className="mySwiper"
                          >
                            {patDeats ? (
                              patDeats.map((item) => (
                                <div>
                                  <SwiperSlide>
                                    <div className="patient-box not-critical">
                                      <div className="patient-info ">
                                        <Link
                                          to={`/fileupload/${item.id}`}
                                          className="file-report"
                                          style={{ marginRight: "2%" }}
                                          href="/#"
                                        >
                                          Add EEG File
                                        </Link>
                                        <img
                                          className="patient-img "
                                          src={patient_image2}
                                          alt="patient-info"
                                        />

                                        <div className="patient-details">
                                          <h4 className="patient-name">
                                            {item.name}
                                          </h4>
                                          <div className="patient-injury">
                                            {item.age}yo|| {item.gender}
                                          </div>
                                          <div className="patient-status">
                                           {item.clinical_condition}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="patient-report">
                                        <Link
                                          to={`/visits/${item.id}`}
                                          className="full-report"
                                          style={{ marginRight: "2%" }}
                                          href="/#"
                                        >
                                          Full Report
                                        </Link>
                                        {/* <a
                                          className="contact-technician"
                                          href="/#"
                                        >
                                          Contact{" "}
                                        </a> */}
                                      </div>
                                    </div>
                                  </SwiperSlide>
                                </div>
                              ))
                            ) : (
                              <div>None</div>
                            )}
                          </Swiper>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-pagination" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
