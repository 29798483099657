import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { configureStore } from "@reduxjs/toolkit";

import {
    doctorListReducer,
    doctorCreateReducer,
    doctorDetailsReducer
} from './reducers/doctorReducers'

import { patientDetailsReducer, patientvisitCreateReducer, patientVisitListReducer, patientdetailDetailsReducer } from './reducers/patientReducers';

import {
    userLoginReducer,
    userRegisterReducer, 
} from './reducers/userReducers'

const reducer = combineReducers({

    doctorList: doctorListReducer,
    doctorCreate: doctorCreateReducer,
    doctorDetails: doctorDetailsReducer,

    patientDetails: patientDetailsReducer,
    patientvisitCreate: patientvisitCreateReducer,
    patientVisitList: patientVisitListReducer,
    patientdetailDetails: patientdetailDetailsReducer,

    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,


    

})

const userInfoFromStorage = localStorage.getItem('userInfo') ?
    JSON.parse(localStorage.getItem('userInfo')) : null


const initialState = {
    userLogin: { userInfo: userInfoFromStorage },
    
    
}

const middleware = [thunk]
const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store



