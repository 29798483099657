import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { login } from "../actions/userActions";
import { LinkContainer } from "react-router-bootstrap";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;

  const history = useNavigate();

  useEffect(() => {
    if (userInfo) {
      history("/dash");
    }
  }, [history, userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(login(email, password));
  };

  return (
    <div>
      <div id="layoutAuthentication_content">
        <main>
          <div className="container-xl px-4">
            <div className="row justify-content-center">
              <header>
                <div className="container ">
                  <Navbar />
                </div>
              </header>
              <div className="col-lg-5">
                <div className="card shadow-lg border-0 rounded-lg mt-5">
                  <div className="card-header justify-content-center">
                    <h3 className="fw-light my-4">Sign In</h3>
                  </div>
                  <div className="card-body">
                    <Form onSubmit={submitHandler}>
                      <Form.Group controlId="email">
                        <Form.Label>
                          Email Address
                          <span style={{ color: "red", marginLeft: "0.1rem" }}>
                            *
                          </span>
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group controlId="password">
                        <Form.Label>
                          Password
                          <span style={{ color: "red", marginLeft: "0.1rem" }}>
                            *
                          </span>
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        ></Form.Control>
                      </Form.Group>

                      <div className="mb-3">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            id="rememberPasswordCheck"
                            type="checkbox"
                            value=""
                          />
                          <label
                            className="form-check-label"
                            for="rememberPasswordCheck"
                          >
                            Remember password
                          </label>
                        </div>
                      </div>

                      <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                        {/* <a className="small" href="auth-password-basic.html">Forgot Password?</a> */}
                        <Button type="submit" variant="teal">
                          Sign in
                        </Button>
                      </div>
                    </Form>
                  </div>
                  <div className="card-footer text-center">
                    <div className="small">
                      <a href="/signup">Need an account? Sign up!</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <div id="layoutDefault_footer">
        <Footer />
      </div>
    </div>
  );
}

export default Login;
