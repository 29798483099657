import axios from "axios";

import {
  
  PATIENT_DETAILS_REQUEST,
  PATIENT_DETAILS_SUCCESS,
  PATIENT_DETAILS_FAIL,

  PATIENTDETAIL_DETAILS_REQUEST,
  PATIENTDETAIL_DETAILS_SUCCESS,
  PATIENTDETAIL_DETAILS_FAIL,

  PATIENTVISIT_CREATE_REQUEST,
  PATIENTVISIT_CREATE_SUCCESS,
  PATIENTVISIT_CREATE_FAIL,
  PATIENTVISIT_CREATE_RESET,

  PATIENTVISIT_LIST_REQUEST,
  PATIENTVISIT_LIST_SUCCESS, 
  PATIENTVISIT_LIST_FAIL, 

  
} from "../constants/patientConstants";




export const patientDetailsReducer = (state = { loading: true, patients: []}, action) => {
    switch (action.type) {
        case PATIENT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
  
        case PATIENT_DETAILS_SUCCESS:
            return {
                loading: false,
                patDeats: action.payload
            }
  
        case PATIENT_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload
            }
  
  
        default:
            return state
    }
  }

  export const patientdetailDetailsReducer = (state = { loading: true, patients: []}, action) => {
    switch (action.type) {
        case PATIENTDETAIL_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
  
        case PATIENTDETAIL_DETAILS_SUCCESS:
            return {
                loading: false,
                patDeats: action.payload
            }
  
        case PATIENTDETAIL_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload
            }
  
  
        default:
            return state
    }
  }

export const patientvisitCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case PATIENTVISIT_CREATE_REQUEST:
            return { loading: true }

        case PATIENTVISIT_CREATE_SUCCESS:
            return { loading: false, success: true, data: action.payload }

        case PATIENTVISIT_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case PATIENTVISIT_CREATE_RESET:
            return {}

        default:
            return state
    }
}

export const patientVisitListReducer = (state = {patients: []}, action) => {
    switch(action.type){
        case PATIENTVISIT_LIST_REQUEST:
            return {loading:true, 
                patients: []
                }

        case PATIENTVISIT_LIST_SUCCESS:
            return {
              
                loading: false,
                patients: action.payload,
               
                }

        case PATIENTVISIT_LIST_FAIL:
            return {loading:true, 
                    error: action.payload
                }

        default:
            return state

    }
}